import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "安徽艾芙特健康护理用品有限公司",
    },
  },
  // 首页
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "关于我们-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 关于我们
  {
    path: "/news/list",
    name: "News",
    component: () => import("../views/News.vue"),
    meta: {
      title: "新闻中心-安徽艾芙特健康护理用品有限公司",
      // keepAlive: false,
    },
  },
  // 新闻列表
  {
    path: "/news/newsInfo",
    name: "NewsInfo",
    component: () => import("../views/NewsInfo.vue"),
    meta: {
      title: "新闻详情-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 新闻详情
  {
    path: "/product/brand",
    name: "Product",
    component: () => import("../views/Product.vue"),
    meta: {
      title: "冠军象-安徽艾芙特健康护理用品有限公司",
    },
    // children: [
    //   {
    //     path: "productInfo",
    //     name: "ProductTwo",
    //     component: () => import("../views/ProductTwo.vue"),
    //     meta: {
    //       title: "本宫月记-安徽艾芙特健康护理用品有限公司",
    //     },
    //   },
    // ],
  },
  // 冠军象
  {
    path: "/product/productInfo",
    name: "ProductTwo",
    // redirect: "/product",
    component: () => import("../views/ProductTwo.vue"),
    meta: {
      title: "本宫月记-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 本宫月记

  {
    path: "/cooperation/atrract",
    name: "Cooperation",
    component: () => import("../views/Cooperation.vue"),
    meta: {
      title: "招商合作-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 招商合作
  {
    path: "/cooperation/information",
    name: "Information",
    component: () => import("../views/Information.vue"),
    meta: {
      title: "采购信息公示-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 信息公示
  {
    path: "/cooperation/info",
    name: "InformationInfo",
    component: () => import("../views/InformationInfo.vue"),
    meta: {
      title: "采购信息公示-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 信息公示详情

  {
    path: "/cooperation/business",
    name: "Business",
    component: () => import("../views/Business.vue"),
    meta: {
      title: "OEM-ODM业务-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 业务

  {
    path: "/recruitment",
    name: "Recruitment",
    component: () => import("../views/Recruitment.vue"),
    meta: {
      title: "人才招聘-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 人才招聘
  {
    path: "/contact",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue"),
    meta: {
      title: "联系我们-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 联系我们
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
    meta: {
      title: "搜索-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 搜索
  {
    path: "/legalNotices",
    name: "LegalNotices",
    component: () => import("../views/LegalNotices.vue"),
    meta: {
      title: "法律声明-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 法律声明
  {
    path: "/technologyEntrance",
    name: "TechnologyEntrance",
    component: () => import("../views/TechnologyEntrance.vue"),
    meta: {
      title: "技术入口-安徽艾芙特健康护理用品有限公司",
    },
  },
  // 技术入口
];

// const scrollBehavior = (to, from, savedPosition) => {
//   if (savedPosition && to.meta.keepAlive) {
//     return savedPosition;
//   }
//   return { left: 0, top: 0 };
// };

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name != "About") {
      if (to.name == from.name) {
        return savedPosition;
      }
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
