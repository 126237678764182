<template>
  <div id="app">
    <HeaderLpk v-show="isHome" />
    <div class="page-view">
      <keep-alive>
        <router-view @onHeadChang="onSidebar" />
      </keep-alive>
    </div>
    <FooterLpk v-show="isHome" />
    <SidebarLpk v-show="isSideShow" />
  </div>
</template>
<script>
import HeaderLpk from "@/views/HeaderLpk.vue";
import FooterLpk from "@/views/FooterLpk.vue";
import SidebarLpk from "@/views/SidebarLpk.vue";

export default {
  name: "Home",
  components: {
    HeaderLpk,
    FooterLpk,
    SidebarLpk,
  },
  data() {
    return {
      isSideShow: false,
      searchData: {},
    };
  },

  computed: {
    isHome() {
      return this.$route.fullPath != "/";
    },
  },
  watch: {
    "$route.path"() {
      this.isSideShow = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (this.isHome) {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;

        if (scrollTop > 100) {
          this.isSideShow = true;
        } else {
          this.isSideShow = false;
        }
      }
    });
  },
  methods: {
    onSidebar(isShow) {
      this.isSideShow = isShow;
    },
  },
};
</script>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.p80 {
  padding-top: 0.8rem;
}
.p80_ {
  padding-bottom: 0.8rem;
}
.m80 {
  margin-top: 0.8rem;
}
.m160 {
  margin: 0 8.333vw;
}
.aft_title {
  .box {
    text-align: center;
    position: relative;
    p {
      font-size: 1.26rem;
      color: #f0f0f0;
      font-family: @gilroy_b;
      text-transform: uppercase;
    }
    div {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      left: 0;
      transform: translateY(-50%);
      font-size: 0.56rem;
    }
  }
}
.aft_banner {
  img {
    width: 100%;
    vertical-align: bottom;
  }
}
.selectToggle {
  .box {
    border-bottom: 1px solid #ddd;
    p {
      margin: 0 0.3rem;
      line-height: 1rem;
      a {
        font-size: 0.18rem;
        color: #666;
      }
    }
    .active {
      a {
        color: @color;
        font-family: @albb_b;
      }
    }
  }
}
.aft_fengye {
  margin: 0.6rem 0;
  .box {
    .btn {
      a {
        display: block;
        p {
          width: 0.6rem;
          height: 0.6rem;
          line-height: 0.6rem;
          text-align: center;
          border-radius: 50%;
          background: #ddd;
          transition: 0.4s;
          display: flex;
          svg {
            margin: auto;
          }
          &:hover {
            background: @color;
          }
        }
      }
      &.prev {
        transform: rotate(180deg);
      }
    }
    .page_ {
      margin: 0 1.2rem;
      line-height: 0.6rem;
      a {
        font-family: @gilroy_b;
        font-size: 0.22rem;
        color: #999;
        margin: 0 0.2rem;
        transition: 0.4s;
        span {
          border-bottom: 2px solid #fff;
          padding-bottom: 0.12rem;
        }
        &:hover {
          color: #67a80c;
        }
      }
      .active {
        color: #000;
        span {
          border-bottom: 2px solid @color;
          padding-bottom: 0.12rem;
        }
      }
    }
  }
}
.aft_banner {
  margin-top: 74px;
}
@media screen and(max-width:1500px) {
  .aft_title .box p {
    font-size: 1rem;
  }
  .aft_title .box div {
    font-size: 0.5rem;
  }
}
@media screen and(max-width:768px) {
  .m160 {
    margin: 0 5vw;
  }
  .aft_fengye .box .page_ a {
    display: none;
  }
  .aft_fengye .box .page_ .active {
    display: block;
  }
  .aft_fengye .box .page_ {
    margin: 0 0.5rem;
  }
  .aft_title .box p {
    font-size: 0.46rem;
  }
  .aft_title .box div {
    font-size: 0.36rem;
  }
  .aft_banner {
    margin: 60px 0 0;
  }
  .aft_banner {
    margin-top: 61px;
  }
}
.page-view {
  min-height: 100vh;
}
</style>
