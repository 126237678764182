import { render, staticRenderFns } from "./FooterLpk.vue?vue&type=template&id=7574aeaa&scoped=true&"
import script from "./FooterLpk.vue?vue&type=script&lang=js&"
export * from "./FooterLpk.vue?vue&type=script&lang=js&"
import style0 from "./FooterLpk.vue?vue&type=style&index=0&id=7574aeaa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7574aeaa",
  null
  
)

export default component.exports