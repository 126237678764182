<template>
  <div class="home">
    <HeaderLpk :isActive="isHeadActive" />
    <div class="fullScreen">
      <div class="swiper-container boxBanner">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="index_banner">
              <div class="banner">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      v-for="(item, index) in banner"
                      :key="index"
                    >
                      <div class="img">
                        <img :src="$host + item.img" alt="" class="lpk_pc" />
                        <img
                          :src="$host + item.imgm"
                          alt=""
                          class="lpk_phone"
                        />
                      </div>
                      <div class="main">
                        <div class="main_">
                          <h5
                            class=""
                            swiper-animate-effect="slideInDown"
                            swiper-animate-duration="0.5s"
                          >
                            <span v-if="item.title != 1">{{ item.title }}</span>
                          </h5>
                          <p
                            class=""
                            swiper-animate-effect="slideInUp"
                            swiper-animate-duration="0.5s"
                          >
                            {{ item.etitle }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page"><p></p></div>

                <div class="button">
                  <p class="prev">
                    <svg
                      t="1656554228234"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="2166"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M378.24 512l418.88 418.88L704 1024 192 512l512-512 93.12 93.12z"
                        fill="#ffffff"
                        p-id="2167"
                      ></path>
                    </svg>
                  </p>
                  <p class="next">
                    <svg
                      t="1656554228234"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="2166"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M378.24 512l418.88 418.88L704 1024 192 512l512-512 93.12 93.12z"
                        fill="#ffffff"
                        p-id="2167"
                      ></path>
                    </svg>
                  </p>
                </div>
              </div>
              <div class="scroolDown" @click="nextCcreen">
                <img src="../assets/images/scroll.png" alt="" />
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="index_video">
              <div class="img tu">
                <img
                  :src="$host + videoMain.img"
                  alt=""
                  class="lpk_pc"
                  v-show="imgHide"
                />
                <img
                  :src="$host + videoMain.imgm"
                  alt=""
                  class="lpk_phone"
                  v-show="imgHide"
                />
                <!-- :poster="$host + videoMain.imgm" videoMain -->
                <video
                  muted
                  class="lpk_pc"
                  :src="$host + videoMain.ivideo"
                  :class="isShow ? 'active' : '_'"
                  preload="none"
                  oncontextmenu="return false"
                  ref="myVideo"
                ></video>
                <!-- :poster="$host + videoMain.img" -->
                <!-- :poster="$host + videoMain.imgm" -->
                <video
                  class="lpk_phone"
                  :src="$host + videoMain.ivideo"
                  :class="isShow ? 'active' : '_'"
                  oncontextmenu="return false"
                  preload="none"
                  ref="myVideo1"
                ></video>
              </div>
              <div
                class="play"
                @click="videPlay"
                :class="{ active: videoState }"
              >
                <img src="../assets/images/play.png" alt="" />
              </div>
              <div class="text" :class="isShow ? 'active' : '_'">
                <p>Brand Video</p>
              </div>

              <div class="scroolDown" @click="nextCcreen">
                <img src="../assets/images/scroll.png" alt="" class="active" />
              </div>
            </div>
          </div>

          <div class="swiper-slide">
            <div class="index_advertise">
              <div class="banner">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      v-for="(item, index) in ibrand"
                      :key="index"
                    >
                      <!-- <a href="#/about">  </a> -->
                      <router-link :to="item.link">
                        <img :src="$host + item.img" alt="" class="lpk_pc" />
                        <img
                          :src="$host + item.img1366"
                          alt=""
                          class="lpk_1366"
                        />
                        <img
                          :src="$host + item.imgm"
                          alt=""
                          class="lpk_phone"
                        />
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="page flexCenter">
                  <p></p>
                </div>
              </div>
              <div class="scroolDown" @click="nextCcreen">
                <img src="../assets/images/scroll.png" alt="" class="active" />
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="index_responsibility">
              <div class="box">
                <div class="img">
                  <img :src="$host + shzr.bg" alt="" class="lpk_pc" />
                  <img :src="$host + shzr.imgm" alt="" class="lpk_phone" />
                </div>
                <div class="main">
                  <div>
                    <div class="content1">
                      <span>{{ shzr.etitle }}</span>
                      <p>{{ shzr.title }}</p>
                    </div>
                    <div class="content2 flexCenter">
                      <div class="index_link">
                        <router-link :to="shzr.link"
                          ><span>了解更多</span></router-link
                        >
                        <!-- <a :href=""></a> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroolDown" @click="nextCcreen">
                <img src="../assets/images/scroll.png" alt="" />
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="index_news">
              <div class="box">
                <div class="biaoti">
                  <span>news</span>
                  <p>新闻资讯</p>
                </div>
                <div class="banner">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, index) in newBanner"
                        :key="index"
                      >
                        <router-link :to="'/news/newsInfo?id=' + item.aid">
                          <div class="content1">
                            <img :src="$host + item.img" alt="" />
                          </div>
                          <div class="content2">
                            <h6 class="ellipsis">
                              {{ item.title }}
                            </h6>
                            <p>
                              {{
                                (item.add_time * 1000) | dateFmt("YYYY-MM-DD")
                              }}
                            </p>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="page flexCenter">
                    <p></p>
                  </div>
                </div>
                <div class="link flexCenter">
                  <div class="index_link">
                    <router-link to="/news/list"
                      ><span>了解更多</span></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="scroolDown" @click="nextCcreen">
                <img src="../assets/images/scroll.png" alt="" class="active" />
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="index_responsibility">
              <div class="box">
                <div class="img">
                  <img :src="$host + zxjm.img" alt="" class="lpk_pc" />
                  <img :src="$host + zxjm.imgm" alt="" class="lpk_phone" />
                </div>
                <div class="main">
                  <div>
                    <div class="content1">
                      <span>{{ zxjm.englist_name }}</span>
                      <p>{{ zxjm.typename }}</p>
                    </div>
                    <div class="content3">
                      <h6>{{ zxjm.title }}</h6>
                      <p>{{ zxjm.etitle }}</p>
                    </div>
                    <div class="content2 flexCenter">
                      <div class="index_link">
                        <!-- <a :href="zxjm.link"><span>立即加盟</span></a> -->
                        <router-link :to="zxjm.link"
                          ><span>立即加盟</span></router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="scroolDown">
                <img
                  src="../assets/images/scroll.png"
                  alt=""
                  class="active"
                  @click="nextCcreen"
                />
              </div> -->
            </div>
          </div>

          <div class="swiper-slide" style="height: auto">
            <FooterLpk />
          </div>
        </div>
      </div>

      <div class="boxPage" :class="{ active: pageShow }" v-show="pageShowHide">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderLpk from "@/views/HeaderLpk.vue";
import FooterLpk from "@/views/FooterLpk.vue";
import Swiper from "swiper";
import {
  swiperAnimateCache,
  swiperAnimate,
} from "../assets/js/swiper.animate1.0.2.min.js";

export default {
  name: "Home",
  components: {
    FooterLpk,
    HeaderLpk,
  },
  data() {
    return {
      isShow: false,
      videoState: false,
      isSidebar: false,
      isHeadActive: false,
      imgHide: true,
      pageArr: [
        "首屏",
        "品牌视频",
        "旗下品牌",
        "社会责任",
        "新闻资讯",
        "在线加盟",
      ],
      pageShow: false,
      pageShowHide: true,
      banner: [],
      videoMain: {},
      ibrand: [],
      shzr: {
        link: "",
      },
      newBanner: [],
      zxjm: {
        link: "",
      },
    };
  },
  created() {
    this.$http.get("api.php/Webinfo/homeinfo").then(
      function (res) {
        const HomeData = JSON.parse(res.data);
        this.banner = HomeData.banner;
        this.videoMain = HomeData.ivideo;
        this.ibrand = HomeData.ibrand;
        this.shzr = HomeData.shzr;
        this.newBanner = HomeData.newsTopList;
        this.zxjm = HomeData.zxjm;

        // console.log(HomeData);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
  mounted() {
    // console.log(this.$api);

    const that = this;
    that.slideItem = new Swiper(".boxBanner", {
      direction: "vertical",
      slidesPerView: "auto",
      // autoHeight: true,
      spaceBetween: 0,
      mousewheel: {
        thresholdTime: 1200,
        releaseOnEdges: true,
      },
      speed: 800,

      pagination: {
        el: ".boxPage div",
        type: "custom", //分页器类型 自定义
        clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换。
        //自定义特殊类型分页器，当分页器类型设置为自定义时可用。
        // swiper, current, total
        renderCustom: function (swiper, current) {
          if (current == 7) {
            that.pageShowHide = false;
          }

          var customPaginationHtml = "";
          for (var i = 0; i < that.pageArr.length; i++) {
            //判断哪个分页器此刻应该被激活
            if (i == current - 1) {
              customPaginationHtml += `<span class="swiper-pagination-bullet swiper-pagination-bullet-active"><font>${that.pageArr[i]}</font></span>`;
            } else {
              customPaginationHtml += `<span class="swiper-pagination-bullet"><font>${that.pageArr[i]}</font></span>`;
            }
          }

          return customPaginationHtml;
        },
      },

      on: {
        slideChangeTransitionEnd: function () {
          // 侧边分页是否显示
          that.pageShowHide = true;

          var current = this.realIndex;
          // console.log(current);

          if (current > 1) {
            that.$emit("onHeadChang", true);
          } else {
            that.$emit("onHeadChang", false);
          }

          that.imgHide = true;
          // 视频的样式
          if (current == 1) {
            that.isShow = true;
            that.videoState = true;
          }
          if (current != 1) {
            that.isShow = false;
            that.$refs.myVideo.pause();
            that.$refs.myVideo1.pause();
            // that.videoState = false;
          }

          // 头部样式
          that.isHeadActive = false;
          // 侧边栏样式
          that.isSidebar = false;
          if (current > 0) {
            that.isHeadActive = true;
            that.isSidebar = true;
          }

          // 侧边分页样式
          that.pageShow = false;
          if (current == 1 || current == 2 || current == 4) {
            that.pageShow = true;
          }
        },
      },
    });
  },
  methods: {
    videPlay() {
      if (this.$refs.myVideo.paused) {
        this.$refs.myVideo.play();
        this.videoState = false;
        this.imgHide = false;
      } else {
        this.$refs.myVideo.pause();
        this.videoState = true;
        this.imgHide = true;
      }
      if (this.$refs.myVideo1.paused) {
        this.$refs.myVideo1.play();
        this.videoState = false;
        this.imgHide = false;
      } else {
        this.$refs.myVideo1.pause();
        this.videoState = true;
        this.imgHide = true;
      }
    },
    nextCcreen() {
      this.slideItem.slideNext();
    },
  },
  watch: {
    banner: {
      handler() {
        this.$nextTick(function () {
          const wid = window.screen.width;
          const indexBanner = new Swiper(".index_banner .swiper-container", {
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            // effect: "fade",
            loop: true,
            speed: 800,
            pagination: {
              el: ".index_banner .banner .page p",
              type: "fraction",
            },
            observer: true,
            observeParents: true,
            // slidesOffsetBefore: wid,
            navigation: {
              prevEl: ".index_banner .banner .prev",
              nextEl: ".index_banner .banner .next",
            },
            on: {
              init: function () {
                swiperAnimateCache(this);
                swiperAnimate(this);
              },
              slideChangeTransitionEnd: function () {
                swiperAnimate(this);
                // console.log(wid);
              },
            },
          });

          indexBanner.update();

          // indexBanner.slideNext();
        });
      },
    },

    ibrand: {
      handler() {
        this.$nextTick(() => {
          // 第三屏

          new Swiper(".index_advertise .swiper-container", {
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            effect: "fade",
            loop: true,
            speed: 800,
            pagination: {
              el: ".index_advertise .banner .page p",
              clickable: true,
            },
          });
        });
      },
    },
    newBanner: {
      handler() {
        this.$nextTick(() => {
          // 第五屏  新闻
          new Swiper(".index_news .swiper-container", {
            slidesPerView: 3,
            spaceBetween: 30,
            mousewheel: true,
            speed: 800,
            pagination: {
              el: ".index_news .banner .page p",
              clickable: true,
            },
            on: {
              init: function () {
                swiperAnimateCache(this);
                swiperAnimate(this);
              },
              slideChangeTransitionEnd: function () {
                swiperAnimate(this);
              },
            },
            breakpoints: {
              320: {
                spaceBetween: 0,
                slidesPerView: 1,
              },
              1200: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              1366: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              1600: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
            },
          });
        });
      },
    },
  },
};
</script>
<style lang="less">
.fullScreen {
  height: 100vh;
  & > .swiper-container {
    width: 100%;
    height: 100%;
    & > .swiper-wrapper {
      transform: translate3d(0, 0, 0);
      & > .swiper-slide {
        height: 100%;
        width: 100%;
        transform: translate3d(0, 0, 0);
        overflow: hidden;

        & > div {
          height: 100%;
          position: relative;
          overflow: hidden;
        }
      }
      // .swiper-slide * {
      //   transform: translate3d(0, 0, 0);
      // }
    }
  }
}

@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
// .indexHeader {
//   position: fixed !important;
// }

.boxPage {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 4.68vw;
  z-index: 3;
  span {
    font-size: 0.14rem;
    color: #fff;
    display: block;
    cursor: pointer;
    margin: 3px 0;
    padding-left: 0.34rem;
    width: auto;
    height: auto;
    border-radius: 0;
    background: none;
    opacity: 0.55;
    position: relative;
    transition: 0.4s;
    &:first-child {
      font {
        opacity: 0 !important;
      }
      &:hover {
        font {
          opacity: 0;
        }
      }
    }
    font {
      opacity: 0;
      transition: 0.4s;
    }
    &:hover {
      font {
        opacity: 1;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 10px;
      height: 2px;
      background: #fff;
      transition: 1s;
    }
    &:hover {
      font {
        // color: @color;
      }
    }
  }
  .swiper-pagination-bullet-active {
    font {
      opacity: 1;
    }
    &::after {
      width: 0.22rem;
    }
  }
  &.active {
    span {
      color: #000;
      &::after {
        background: #000;
      }
    }
  }
}

.scroolDown {
  position: absolute;
  left: 4.68vw;
  bottom: 0.56rem;
  z-index: 2;
  color: #fff;
  cursor: pointer;
  img {
    height: 0.37rem;
    cursor: pointer;
  }
  .active {
    filter: invert(0.5);
  }
}

.index_banner {
  position: relative;
  .swiper-slide {
    position: relative;
    .img {
      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
    }
    .main {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      .main_ {
        width: 100%;
        text-align: center;
        h5 {
          font-size: 0.6rem;
          color: #fff;
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          position: relative;
          top: -0.16rem;
          opacity: 0;
          transition: 2s;
        }
        p {
          font-family: @gilroy_r;
          font-size: 0.34rem;
          color: #fff;
          margin-top: 10px;
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          position: relative;
          top: 0.36rem;
          opacity: 0;
          transition: 2s;
        }
      }
    }
  }
  .swiper-slide-active {
    .main {
      .main_ {
        h5 {
          top: 0rem;
          opacity: 1;
        }
        P {
          top: 0rem;
          opacity: 1;
        }
      }
    }
  }
  .banner {
    position: relative;
    .page {
      position: absolute;
      right: 4.68vw;
      bottom: 0.56rem;
      z-index: 2;
      color: #fff;
      span {
        color: #fff;
        font-family: @gilroy_b;
        margin-right: 10px;
      }
      font {
        color: #fff;
        opacity: 0.7;
      }
    }
    .button {
      position: absolute;
      bottom: 0.3rem;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 3;
      svg {
        opacity: 0.5;
        transition: 0.4s;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
      p {
        margin: 0 0.1rem;
      }
      .next {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.index_video {
  position: relative;
  background: #e7f2f8;
  .img {
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      transform: scale(0.7);
      transition: 0.5s;
    }
    .active {
      transform: scale(1);
    }
  }
  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0);

    transition-duration: 0.4s;
    z-index: 2;

    img {
      width: 2rem;
      cursor: pointer;
    }
    &.active {
      transition-delay: 1.5s;
      transform: translateX(-50%) translateY(-50%) scale(1);
    }
  }
  .text {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateX(0%) translateY(-50%);
    opacity: 1;
    transition-duration: 1s;
    transition-delay: 1s;
    z-index: 2;
    p {
      font-size: 1.48rem;
      color: #fff;
      font-family: @gilroy_b;
    }
    &.active {
      left: -100%;
      opacity: 0;
    }
  }
}
.index_advertise {
  .banner {
    position: relative;
    .swiper-slide {
      a {
        display: block;
      }
      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
    }
    & > .page {
      position: absolute;
      bottom: 0.56rem;
      left: 0;
      width: 100%;
      z-index: 2;
      p {
        span {
          width: 0.2rem;
          height: 2px;
          border-radius: 0;
          background: #fff;
          margin: 0 5px;
          opacity: 1;
        }
        .swiper-pagination-bullet-active {
          background: @color;
        }
      }
    }
  }
}

.index_responsibility {
  .box {
    position: relative;
    .img {
      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
    }
    .main {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      & > div {
        width: 100%;
      }
      .content1 {
        text-align: center;
        position: relative;
        span {
          font-size: 1.66rem;
          color: #fff;
          opacity: 0.25;
          font-family: @gilroy_b;
          text-transform: uppercase;
        }
        p {
          font-size: 0.56rem;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .content2 {
        margin-top: 10px;
      }
      .content3 {
        text-align: center;
        margin-bottom: 0.56rem;
        h6 {
          font-size: 0.56rem;
          color: #fff;
        }
        p {
          font-size: 0.34rem;
          color: #fff;
          font-family: @gilroy_r;
        }
      }
    }
  }
}
.index_link {
  a {
    display: block;
    width: 1.8rem;
    line-height: 0.48rem;
    border-radius: 0.24rem;
    background: @color;
    text-align: center;
    transition: 0.4s;
    span {
      font-family: @albb_m;
      font-size: 0.16rem;
      color: #fff;
    }
    &:hover {
      transform: scale(1.06);
    }
  }
}
.index_news {
  .box {
    margin: 0 10.4vw;
    .biaoti {
      text-align: center;
      margin-top: 1.2rem;
      position: relative;
      span {
        font-size: 1.66rem;
        color: #f0f0f0;
        text-transform: uppercase;
      }
      p {
        font-size: 0.56rem;
        color: #000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .banner {
      position: relative;
      .swiper-slide {
        a {
          display: block;
          .content1 {
            overflow: hidden;
            img {
              width: 100%;
              transition: 0.4s;
              vertical-align: bottom;
            }
          }
          .content2 {
            text-align: center;
            h6 {
              font-family: @albb_m;
              font-size: 0.22rem;
              color: #000;
              margin-top: 0.42rem;
              margin-bottom: 0.14rem;
              transition: 0.4s;
            }
            p {
              font-family: @gilroy_r;
              font-size: 0.16rem;
              color: #999;
            }
          }
          &:hover {
            .content1 {
              img {
                transform: scale(1.1);
              }
            }
            .content2 {
              h6 {
                color: @color;
              }
            }
          }
        }
      }
      & > .page {
        margin-top: 0.2rem;
        p {
          span {
            width: 0.2rem;
            height: 2px;
            border-radius: 0;
            background: #ddd;
            margin: 0 5px;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            background: @color;
          }
        }
      }
    }
    .link {
      margin-top: 0.45rem;
    }
  }
}
@media screen and(max-width:1520px) {
  .index_news .box .biaoti span {
    font-size: 1rem;
  }
  .index_video .text p {
    font-size: 1rem;
  }
  .index_responsibility .box .main .content1 span {
    font-size: 1rem;
  }
  .index_responsibility .box .main .content1 span {
    font-size: 1rem;
  }
  .index_news .box .link {
    margin-top: 0.25rem;
  }
  .index_video .play img {
    width: 1.5rem;
  }

  .index_advertise .banner .swiper-slide .lpk_pc {
    display: none;
  }
}

@media screen and(max-width:768px) {
  .scroolDown {
    display: none;
  }
  .index_banner .swiper-slide .main .main_ h5 {
    font-size: 0.34rem;
  }
  .index_banner .swiper-slide .main .main_ p {
    font-size: 0.28rem;
  }
  .index_video .play img {
    width: 1.5rem;
  }
  .index_video .text p {
    font-size: 0.6rem;
  }
  .index_banner .swiper-slide .main .main_ h5 {
    margin-top: 4rem;
  }
  .index_banner .swiper-slide .main {
    align-items: initial;
  }

  .index_responsibility .box .main .content1 span {
    font-size: 0.64rem;
  }
  .index_responsibility .box .main .content1 p {
    font-size: 0.4rem;
  }

  .boxPage {
    display: none;
  }
  .index_news .box .biaoti span {
    font-size: 0.64rem;
  }
  .index_news .box .biaoti {
    margin-top: 2.4rem;
  }
  .index_news .box .biaoti p {
    font-size: 0.36rem;
  }
  .index_news .box .banner {
    margin-top: 0.5rem;
  }
}
@media screen and(max-width:669px) {
  .index_banner .swiper-slide .main .main_ h5 {
    margin-top: 3.5rem;
  }
  .index_news .box .banner .swiper-slide a .content2 h6 {
    font-size: 0.26rem;
  }
  .index_news .box .banner .swiper-slide a .content2 p {
    font-size: 0.2rem;
  }

  .index_link a {
    width: 2rem;
    line-height: 0.54rem;
    border-radius: 0.3rem;
  }
  .index_link a span {
    font-size: 0.2rem;
  }
  .index_responsibility .box .main .content1 p {
    font-size: 0.36rem;
  }
  .index_responsibility .box .main .content3 h6 {
    font-size: 0.46rem;
  }
}
</style>
