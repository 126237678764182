import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "swiper/css/swiper.min.css";
import "swiper/js/swiper.min.js";

import animated from "animate.css";
// import BaiduMap from "vue-baidu-map";
import VueResource from "vue-resource";
import wow from "wowjs";
import moment from "moment";

import VueBus from "vue-bus";
import "@/icons";

Vue.use(VueBus);
// import "vue-awesome-swiper/index.js";
// Vue.use(VueAwesomeSwiper);
// import $ from "jquery";
//全局过滤器
Vue.filter("dateFmt", (input, formatString = "YYYY-MM-DD") => {
  //es5函数参数设置默认值
  //const lastFormatString = formatString || ''
  // moment(input) 把时间字符串转成时间对象
  // format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串
  return moment(input).format(formatString);
  // | dateFmt("YYYY-MM-DD HH:mm:ss")
});

// Vue.use(BaiduMap, {
//   ak: "skgEwTsrs5k8uZ4V7RqVbvBX&amp",
// });

import VueAMap from "vue-amap";
Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: "your amap key",
  plugin: [
    "MarkerClusterer",
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4",
});

Vue.use(animated);
Vue.use(VueResource);

Vue.prototype.$wow = wow;
Vue.prototype.$host = "http://aftcs.zhulu76.com";
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
