<template>
  <div class="Sidebar">
    <ul class="">
      <li>
        <a :href="FootInfo.taobao" target="_blank"
          ><p><img src="../assets/images/icon6.png" alt="" /></p
        ></a>

        <!-- <div><img :src="$host + FootInfo.taobao" alt="" /></div> -->
      </li>
      <li>
        <p><img src="../assets/images/icon7.png" alt="" /></p>
        <div><img :src="$host + FootInfo.weixin" alt="" /></div>
      </li>
      <li>
        <a :href="FootInfo.pdd" target="_blank">
          <p><img src="../assets/images/icon8.png" alt="" /></p
        ></a>
        <!-- <div><img :src="$host + FootInfo.pdd" alt="" /></div> -->
      </li>
      <li>
        <a :href="FootInfo.douyin" target="_blank">
          <p>
            <img src="../assets/images/icon9.png" alt="" />
          </p>
        </a>

        <!-- <div><img :src="$host + FootInfo.douyin" alt="" /></div> -->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      FootInfo: {},
    };
  },
  created() {
    this.$http.get("api.php/Webinfo/footinfo").then(
      function (res) {
        const FootInfo = JSON.parse(res.data);
        this.FootInfo = FootInfo;
        // console.log(FootInfo);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
};
</script>

<style lang="less" scoped>
.Sidebar {
  position: fixed;
  right: 0.32rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;

  ul {
    li {
      margin: 6px 0;
      position: relative;
      p {
        width: 0.44rem;
        height: 0.44rem;
        border-radius: 50%;
        border: 1px solid #333;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.4s;
        img {
          margin: auto;
          filter: invert(1);
          transition: 0.4s;
        }
      }
      div {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform-origin: right center;
        transform: translateY(-50%) scale(0);
        transition: 0.3s;
        img {
          width: 1.2rem;
        }
      }
      &:hover {
        p {
          background: #97c852;
          border: 1px solid #97c852;
          img {
            filter: invert(0);
          }
        }
        div {
          transform: translateY(-50%) scale(1);
        }
      }
    }
  }
}
@media screen and(max-width:768px) {
  .Sidebar {
    right: 3vw;
    top: 50%;
    ul li p img {
      transform: scale(0.7);
    }
  }
}
</style>
