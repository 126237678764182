<template>
  <div>
    <div class="indexHeader lpk_pc" :class="{ active: isActive }">
      <div class="box flexBetween">
        <div class="boxLeft">
          <router-link to="/">
            <svg-icon icon-class="logo1" className="lpkicon img1" />
            <svg-icon icon-class="logo" className="lpkicon img2" />
            <!-- <img src="../assets/images/logo.svg" alt="" class="img1" /> -->
            <!-- <img src="../assets/images/logo1.png" alt="" class="img2" /> -->
          </router-link>
        </div>
        <div class="boxCenter">
          <div
            class="nav flexCenter"
            :class="{ on: active1, on1: active2, on2: active3 }"
          >
            <div class="item" v-for="(item, index) in routes" :key="index">
              <p>
                <router-link :to="item.src" routerLinkActive="active">
                  {{ item.title }}</router-link
                >
                <!-- <span @click="navClick">1232</span> -->
              </p>
              <div>
                <ul v-show="item.children.length > 1">
                  <li v-for="(itm, inx) in item.children" :key="inx">
                    <router-link :to="itm.src"
                      ><span>{{ itm.title }}</span></router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="right_right">
          <div class="boxRight flexEnd">
            <p @click="searchShow">
              <img src="../assets/images/search.png" alt="" v-show="search" />
            </p>

            <form v-show="!search" @submit.prevent>
              <div class="search_box">
                <div class="flexBetween">
                  <div class="search_input">
                    <input
                      type="text"
                      v-model="searchmain"
                      placeholder="请搜索"
                      @keyup.enter="searchText"
                    />
                    <p @click="searchText">
                      <label>
                        <img src="../assets/images/search1.png" alt="" />
                        <!-- <input type="submit" value="" /> -->
                      </label>
                    </p>
                  </div>
                  <div class="search_blose" @click="searchHide">
                    <img src="../assets/images/close.png" alt="" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="indexHeader lpk_phone" :class="{ active: isActive }">
      <div class="box flexBetween">
        <div class="boxLeft">
          <a href="/"
            ><img src="../assets/images/logo.png" alt="" class="img1" />
            <img src="../assets/images/logo1.png" alt="" class="img2"
          /></a>
        </div>
        <div class="boxCenter" :class="{ active: isNavShow }">
          <div class="nav flexCenter">
            <div class="item" v-for="(item, index) in routesPhone" :key="index">
              <p @click="NavTogg(index)" v-if="item.children.length > 1">
                {{ item.title }} <span :class="{ active: item.status }"></span>
              </p>

              <p v-else @click="NavToggHide">
                <router-link :to="item.src">
                  {{ item.title }}
                  <font
                    ><svg
                      t="1659924331699"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="2226"
                      width="14"
                      height="14"
                    >
                      <path
                        d="M353.045333 86.826667L754.56 488.362667a32 32 0 0 1 2.090667 42.965333l-2.090667 2.282667L353.066667 935.168a8.533333 8.533333 0 0 1-6.037334 2.496h-66.368a8.533333 8.533333 0 0 1-6.037333-14.570667L686.72 511.018667 274.602667 98.901333a8.533333 8.533333 0 0 1 6.037333-14.570666h66.346667a8.533333 8.533333 0 0 1 6.058666 2.496z"
                        fill="#333333"
                        p-id="2227"
                      ></path></svg
                  ></font>
                </router-link>
              </p>

              <div v-show="item.status">
                <ul>
                  <li
                    v-for="(itm, inx) in item.children"
                    :key="inx"
                    @click="NavToggHide"
                  >
                    <router-link :to="itm.src"
                      ><span>{{ itm.title }}</span></router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="right_right">
          <div class="boxRight flexEnd">
            <p @click="searchShow">
              <img src="../assets/images/search.png" alt="" v-show="search" />
            </p>

            <div action="" v-show="!search">
              <div class="search_box">
                <div class="flexBetween">
                  <div class="search_input">
                    <input
                      type="text"
                      v-model="searchmain"
                      placeholder="请搜索"
                    />
                    <p @click="searchText">
                      <label>
                        <img src="../assets/images/search1.png" alt="" />
                        <!-- <input type="submit" value="" /> -->
                      </label>
                    </p>
                  </div>
                  <div class="search_blose" @click="searchHide">
                    <img src="../assets/images/close.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lpk_phone">
            <div class="menu" @click="NavShow">
              <div class="menu_btn" :class="{ active: isNavShow }">
                <p>
                  <span></span>
                  <span></span>
                  <span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderLpk",
  data() {
    return {
      isON: true,
      search: true,
      searchmain: "",
      currentIndex: this.$route.query.currentIndex || 0,
      isNavShow: false,
      active1: false,
      active2: false,
      active3: false,
      routes: [
        {
          id: 1,
          title: "关于艾芙特",
          src: "/about",
          children: [
            { id: 11, title: "企业介绍", src: "/about?id=0" },
            { id: 12, title: "事业理论", src: "/about?id=1" },
            { id: 15, title: "CEO致辞", src: "/about?id=2" },
            { id: 14, title: "社会责任", src: "/about?id=3" },
            { id: 13, title: "事业领域", src: "/about?id=4" },
            { id: 16, title: "企业荣誉", src: "/about?id=5" },
          ],
        },
        {
          id: 2,
          title: "旗下品牌",
          src: "/product/brand",
          children: [
            { id: 21, title: "冠军象", src: "/product/brand" },
            { id: 22, title: "本宫月记", src: "/product/productInfo" },
          ],
        },
        {
          id: 3,
          title: "新闻中心",
          src: "/news/list",
          children: [],
        },
        {
          id: 4,
          title: "招商合作",
          src: "/cooperation/atrract",
          children: [
            { id: 21, title: "招商合作", src: "/cooperation/atrract" },
            { id: 22, title: "OEM-ODM业务", src: "/cooperation/business" },
            { id: 23, title: "采购信息公示", src: "/cooperation/information" },
          ],
        },
        {
          id: 5,
          title: "人才招聘",
          src: "/recruitment",
          children: [],
        },
        {
          id: 6,
          title: "联系我们",
          src: "/contact",
          children: [],
        },
      ],

      routesPhone: [
        {
          id: 1,
          title: "关于艾芙特",
          src: "/about",
          status: false,
          children: [
            { id: 11, title: "企业介绍", src: "/about?id=0" },
            { id: 12, title: "事业理论", src: "/about?id=1" },
            { id: 15, title: "CEO致辞", src: "/about?id=2" },
            { id: 14, title: "社会责任", src: "/about?id=3" },
            { id: 13, title: "事业领域", src: "/about?id=4" },
            { id: 16, title: "企业荣誉", src: "/about?id=5" },
          ],
        },
        {
          id: 2,
          title: "旗下品牌",
          src: "/product/brand",
          status: false,
          children: [
            { id: 21, title: "冠军象", src: "/product/brand" },
            { id: 22, title: "本宫月记", src: "/product/productInfo" },
          ],
        },
        {
          id: 3,
          title: "新闻中心",
          src: "/news/list",
          status: false,
          children: [],
        },
        {
          id: 4,
          title: "招商合作",
          src: "/cooperation/atrract",
          status: false,
          children: [
            { id: 21, title: "招商合作", src: "/cooperation/atrract" },
            { id: 22, title: "OEM-ODM业务", src: "/cooperation/business" },
            { id: 23, title: "采购信息公示", src: "/cooperation/information" },
          ],
        },
        {
          id: 5,
          title: "人才招聘",
          src: "/recruitment",
          status: false,
          children: [],
        },
        {
          id: 6,
          title: "联系我们",
          src: "/contact",
          status: false,
          children: [],
        },
      ],
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },

    seachText: {
      type: String,
    },
    // menu_data: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
  },

  created() {},
  mounted() {
    // console.log(this.searchmain);
  },
  methods: {
    // navClick() {
    //   console.log(123);
    //   this.$router.push("/product/productInfo");
    // },
    changeHandler(i) {
      this.currentIndex = i;
    },
    searchShow() {
      this.search = false;
    },
    searchHide() {
      this.search = true;
    },
    searchText() {
      const that = this;
      if (this.searchmain != "") {
        this.$http
          .get(
            "api.php/Webinfo/searchList",
            {
              params: {
                keywords: this.searchmain,
              },
            },
            {
              emulateJSON: true,
            }
          )
          .then(
            function (res) {
              const news_data = JSON.parse(res.data);
              // this.new_list = news_data.news_list;
              // this.searchesNum = news_data.total;
              news_data.title = this.searchmain;

              that.$bus.emit("lowerRight", news_data);
              this.searchmain = "";

              // console.log(news_data);
            },
            function (res) {
              console.log(res.status);
            }
          );

        this.$router.push({
          name: "Search",
          params: { pk_refinfo: this.searchmain },
        });
      }
    },
    NavShow() {
      this.isNavShow = !this.isNavShow;
    },
    NavTogg(index) {
      this.routesPhone[index].status = !this.routesPhone[index].status;
    },
    NavToggHide() {
      this.isNavShow = false;
      for (var i = 0; i < this.routesPhone.length; i++) {
        this.routesPhone[i].status = false;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == "ProductTwo") {
        this.active1 = true;
      } else if (to.name == "NewsInfo") {
        this.active2 = true;
      } else if (
        to.name == "Business" ||
        to.name == "Information" ||
        to.name == "InformationInfo"
      ) {
        this.active3 = true;
      } else {
        this.active1 = false;
        this.active2 = false;
        this.active3 = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.lpkicon {
  height: 33px;
}
.indexHeader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 8;
}
.indexHeader.active {
  // position: fixed;
}
.indexHeader {
  .box {
    margin: 0 4.68vw;
    .boxLeft {
      width: 20%;
      display: flex;
      align-items: center;
      a {
        img {
          height: 33px;
        }
        .img2 {
          display: none;
        }
      }
    }
    .boxCenter {
      .nav {
        &.on {
          .item:nth-child(2) {
            & > p {
              a {
                color: #97c852;
              }
            }
          }
        }
        &.on1 {
          .item:nth-child(3) {
            & > p {
              a {
                color: #97c852;
              }
            }
          }
        }
        &.on2 {
          .item:nth-child(4) {
            & > p {
              a {
                color: #97c852;
              }
            }
          }
        }

        .item {
          color: #fff;
          padding: 0 25px;
          height: 73px;
          display: flex;
          align-items: center;
          position: relative;
          & > p {
            line-height: 33px;
            a {
              color: #e6e6e6;
              font-size: 20px;
              transition: 0.4s;
              &:hover {
                color: @color;
              }
            }
            .router-link-active {
              color: @color;
            }
          }
          & > div {
            display: none;
          }
          ul {
            position: absolute;
            top: 73px;
            left: 0;
            width: 100%;
            background: #fff;
            padding: 20px 0;
            border-radius: 8px;

            &::after {
              content: "";
              position: absolute;
              top: -16px;
              left: 50%;
              transform: translateX(-50%);
              border: 8px solid transparent;
              border-bottom-color: #fff;
            }
            li {
              margin-bottom: 18px;
              text-align: center;
              &:last-child {
                margin-bottom: 0;
              }
              a {
                font-size: 16px;
                color: #666;
                transition: 0.4s;
                span {
                  display: inline-block;
                  padding-bottom: 12px;
                }
                &:hover {
                  color: @color;
                }
              }
            }
            .active {
              a {
                span {
                  border-bottom: 1px solid @color;
                }
              }
            }
          }
          // &:hover {
          //   & > div {
          //     display: block;
          //     ul {
          //     }
          //   }
          // }
        }
      }
    }
    .right_right {
      width: 20%;
      p {
        // cursor: pointer;
      }
    }
    .boxRight {
      // width: 20%;
      align-items: center;
      position: relative;
      height: 100%;
      & > p {
        cursor: pointer;
      }
    }
  }
}
@media screen and(min-width:768px) {
  .indexHeader .box .boxCenter .nav .item:hover > div {
    display: block;
  }
}

.search_box {
  width: 346px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  .search_input {
    background: #eee;
    width: 86%;
    border-radius: 25px;
    position: relative;
    & > input {
      display: block;
      height: 46px;
      background: none;
      border: 0;
      box-sizing: border-box;
      padding: 0 16px;
      font-size: 16px;
      color: #999;
      width: 100%;
    }
    p {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 4;
      label {
        display: block;
        cursor: pointer;
      }
      img {
        cursor: pointer;
      }
      input {
        display: none;
        cursor: pointer;
      }
    }
  }
  .search_blose {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.indexHeader.active {
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid #f5f5f5;
  .box {
    .boxLeft {
      a {
        .img1 {
          display: none;
        }
        .img2 {
          display: block;
        }
      }
    }
    .boxCenter {
      .nav {
        .item {
          & > p {
            a {
              color: #666;

              &:hover {
                color: @color;
              }
            }
            .router-link-active {
              color: @color;
            }
          }
        }
      }
    }
    .boxRight {
      p {
        & > img {
          filter: invert(0.7);
        }
      }
    }
  }
}

@media screen and(max-width:1440px) {
  .indexHeader .box .right_right {
    width: auto;
  }
}
@media screen and(max-width:768px) {
  .indexHeader {
    // position: sticky;
  }
  .indexHeader .box .boxCenter {
    display: none;
  }
  .indexHeader {
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid #f5f5f5;
    padding: 15px 0;
    .box {
      .boxLeft {
        a {
          img {
            height: 25px;
          }
          .img1 {
            display: none;
          }
          .img2 {
            display: block;
          }
        }
      }
      .boxCenter {
        .nav {
          .item {
            & > p {
              a {
                color: #666;

                &:hover {
                  color: @color;
                }
              }
              .router-link-active {
                color: @color;
              }
            }
          }
        }
      }
      .boxRight {
        p {
          & > img {
            filter: invert(0.7);
          }
        }
      }
    }
  }
  .search_box {
    width: 92vw;
    position: absolute;
    top: 10px;
    transform: translateY(100%);
    right: 0;
  }
  .right_right {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .menu {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    .menu_btn {
      p {
        position: relative;
        span {
          display: block;
          width: 25px;
          height: 2px;
          background: @color;
          margin: 6px 0;
          &:nth-child(1) {
            transition: 0.4s;
          }
          &:nth-child(3) {
            transition: 0.4s;
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          width: 100%;
          height: 2px;
          background: @color;
          top: 50%;
          margin-top: -1px;
          transition: 0.4s;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          width: 100%;
          height: 2px;
          background: @color;
          top: 50%;
          margin-top: -1px;
          transition: 0.4s;
        }
      }
      &.active {
        p {
          span {
            opacity: 0;
          }
          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .indexHeader .box .boxRight {
    position: initial;
  }
  .indexHeader .box .boxCenter {
    // display: block;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(100% - 60px);
    background: #fff;
    box-sizing: border-box;
    padding: 20px 0 0;
    z-index: 5;
    overflow: scroll;
  }
  .indexHeader .box .boxCenter .nav .item {
    width: 100%;
    height: auto;
  }
  .indexHeader .box .boxCenter .nav .item > p {
    width: 100%;
    a {
      font-size: 16px;
    }
  }
  .indexHeader .box .boxCenter .nav .item > div {
    // display: block;
    width: 100%;
  }
  .indexHeader .box .boxCenter .nav .item ul {
    position: initial;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 20px 0;
    border-radius: 8px;
  }
  .indexHeader .box .boxCenter .nav .item {
    flex-wrap: wrap;
  }
  .indexHeader .box .boxCenter .nav .item ul li {
    margin-bottom: 10px;
    text-align: left;
    padding-left: 20px;
  }
  .indexHeader .box .boxCenter.active {
    display: block;
  }
  .search_box {
    top: 60px;
    transform: translateY(0%);
  }
  .indexHeader .box .boxCenter .nav .item > div {
    display: block;
  }
  .indexHeader .box .boxCenter .nav .item {
    color: #666;
    font-size: 16px;
  }
  .indexHeader .box .boxCenter .nav .item > p {
    font {
      display: block;
      position: absolute;
      right: 25px;
      top: 2px;
    }
    span {
      display: block;
      width: 14px;
      height: 1px;
      background: #333;
      position: absolute;
      right: 25px;
      top: 16px;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: #333;
        transform: rotate(90deg);
        transition: 0.4s;
      }
      &.active {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .indexHeader .box .boxRight > p {
    // display: none;
  }
}
</style>
