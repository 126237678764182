<template>
  <div class="index_footer">
    <div class="box">
      <div class="boxTop flexBetween">
        <div class="boxleft">
          <img src="../assets/images/logo1.png" alt="" />
        </div>
        <div class="boxRight flexBetween">
          <div class="item">
            <p><img src="../assets/images/icon1.png" alt="" /></p>
            <div class="img"><img :src="$host + FootInfo.weixin" alt="" /></div>
          </div>
          <div class="item">
            <p>
              <a :href="FootInfo.douyin" target="_blank"
                ><img src="../assets/images/icon2.png" alt=""
              /></a>
            </p>
            <!-- <div class="img"><img :src="$host + FootInfo.douyin" alt="" /></div> -->
          </div>
          <div class="item">
            <p>
              <a :href="FootInfo.weibo" target="_blank"
                ><img src="../assets/images/icon3.png" alt=""
              /></a>
            </p>
          </div>
        </div>
      </div>
      <div class="boxCen flexBetween">
        <div class="boxLeft flexBetween">
          <div class="item">
            <h5>关于艾芙特</h5>
            <ul>
              <li>
                <router-link to="/about?id=0" @click.native="productTop4('0')"
                  >企业介绍</router-link
                >
              </li>
              <li>
                <router-link to="/about?id=1" @click.native="productTop4('1')"
                  >事业理论</router-link
                >
              </li>
              <li>
                <router-link to="/about?id=2" @click.native="productTop4('2')"
                  >CEO致辞</router-link
                >
              </li>
              <li>
                <router-link to="/about?id=3" @click.native="productTop4('3')"
                  >社会责任</router-link
                >
              </li>
              <li>
                <router-link to="/about?id=4" @click.native="productTop4('4')"
                  >事业领域</router-link
                >
              </li>
              <li>
                <router-link to="/about?id=5" @click.native="productTop4('5')"
                  >企业荣誉</router-link
                >
              </li>
            </ul>
          </div>
          <div class="item">
            <h5>冠军象</h5>
            <ul>
              <li>
                <router-link
                  to="/product/brand?top=top1"
                  @click.native="productTop('top1')"
                  >品牌故事</router-link
                >
              </li>
              <li>
                <router-link
                  to="/product/brand?top=top2"
                  @click.native="productTop('top2')"
                  >产品介绍</router-link
                >
              </li>

              <li>
                <a href="https://guanjunxiang.tmall.com" target="_blank"
                  >店铺链接</a
                >
              </li>
            </ul>
          </div>

          <div class="item">
            <h5>本宫月记</h5>
            <ul>
              <li>
                <router-link
                  to="/product/productInfo?top=top1"
                  @click.native="productTop2('top1')"
                  >品牌故事</router-link
                >
              </li>
              <li>
                <router-link
                  to="/product/productInfo?top=top2"
                  @click.native="productTop2('top2')"
                  >产品介绍</router-link
                >
              </li>
              <li>
                <a href="https://bengongyueji.tmall.com " target="_blank"
                  >店铺链接</a
                >
              </li>
            </ul>
          </div>
          <div class="item">
            <h5>人才招聘</h5>
            <ul>
              <li>
                <router-link
                  to="/recruitment?top=top1"
                  @click.native="productTop3('top1')"
                  >加入我们</router-link
                >
              </li>
              <li>
                <router-link
                  to="/recruitment?top=top2"
                  @click.native="productTop3('top2')"
                  >人才战略</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="boxCenter">
          <div class="content1">电话</div>

          <div class="content2" @click="callPhone(FootInfo.tel)">
            {{ FootInfo.tel }}
          </div>
          <div class="content3 flexEnd">
            <p @click="callEmail(FootInfo.email)">
              <img src="../assets/images/icon4.png" alt="" />
            </p>
            <p>
              <router-link to="/contact"
                ><img src="../assets/images/icon5.png" alt=""
              /></router-link>
            </p>
          </div>
        </div>
        <div class="boxRight">
          <div class="content1">
            <img :src="$host + FootInfo.weixin" alt="" />
          </div>
          <div class="content2">微信公众号</div>
        </div>
      </div>
      <div class="boxBot flexBetween">
        <div class="left">
          {{ FootInfo.copyright }}
          <a href=""> {{ FootInfo.icp }}</a>
          <a target="_blank" href="http://zhulu86.com"> 技术支持：逐鹿科技</a>
        </div>
        <div class="right">
          <a href=""></a>
          <router-link to="/technologyEntrance">隐私政策</router-link>
          <router-link to="/legalNotices">法律声明</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterLpk",
  data() {
    return {
      FootInfo: {},
    };
  },
  created() {
    this.$http.get("api.php/Webinfo/footinfo").then(
      function (res) {
        const FootInfo = JSON.parse(res.data);
        this.FootInfo = FootInfo;

        // console.log(FootInfo);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
  methods: {
    productTop(top) {
      this.$bus.emit("productTops", top);
    },
    productTop2(top) {
      this.$bus.emit("productTops2", top);
    },
    productTop3(top) {
      this.$bus.emit("productTops3", top);
    },
    productTop4(top) {
      this.$bus.emit("productTops4", top);
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    callEmail(callEmail) {
      window.location.href = "mailto:" + callEmail;
    },
  },
};
</script>
<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.index_footer {
  background: #f5f5f5;
  .box {
    margin: 0 4.68vw;
    .boxTop {
      padding: 0.7rem 0 0.32rem;
      border-bottom: 1px solid #ddd;
      .boxleft {
        img {
          height: 0.33rem;
        }
      }
      .boxRight {
        .item {
          position: relative;
          & > p {
            width: 0.33rem;
            height: 0.33rem;
            display: flex;
            align-items: center;
            margin-left: 0.18rem;
            cursor: pointer;
            img {
              max-height: 100%;
              max-width: 100%;
              margin: auto;
            }
          }
          .img {
            position: absolute;
            top: 0.4rem;
            left: 50%;
            transform: translateX(-50%);
            display: none;
            z-index: 2;
            img {
              width: 1.3rem;
            }
          }
          &:hover {
            .img {
              display: block;
            }
          }
        }
      }
    }
    .boxCen {
      margin-top: 0.6rem;
      .boxLeft {
        .item {
          margin-right: 2rem;
          h5 {
            font-size: 0.22rem;
            color: #464646;
          }
          ul {
            margin-top: 0.32rem;
            text-align: center;
            li {
              line-height: 0.38rem;
              a {
                font-size: 0.18rem;
                color: #666;
              }
            }
          }
        }
      }
      .boxCenter {
        .content1 {
          font-size: 0.16rem;
          color: #999;
          text-align: right;
        }
        .content2 {
          font-size: 0.3rem;
          color: #464646;
          font-family: @gilroy_b;
          margin-top: 4px;
          cursor: pointer;
        }
        .content3 {
          margin-top: 0.4rem;
          p {
            margin-left: 10px;
            a {
              display: block;
              img {
                width: 0.48rem;
              }
            }
            img {
              width: 0.48rem;
              cursor: pointer;
            }
          }
        }
      }
      .boxRight {
        .content1 {
          img {
            width: 1.35rem;
          }
        }
        .content2 {
          font-size: 0.16rem;
          color: #000;
          text-align: center;
          margin-top: 0.2rem;
        }
      }
    }
    .boxBot {
      line-height: 0.3rem;
      margin-top: 0.76rem;
      border-top: 1px solid #ddd;
      padding: 0.46rem 0;
      .left {
        font-size: 0.16rem;
        color: #666;
        a {
          color: #666;
          margin-left: 5px;
          display: inline-block;
        }
      }
      .right {
        a {
          color: #000;
          font-size: 0.16rem;
          display: inline-block;
          margin-left: 0.2rem;
        }
      }
    }
  }
}
@media screen and(max-width:1680px) {
  .index_footer .box .boxCen .boxLeft .item {
    margin-right: 1.4rem;
  }
}
@media screen and(max-width:1500px) {
  .index_footer .box .boxBot {
    margin-top: 0.4rem;
  }
  .index_footer .box .boxTop {
    padding: 0.4rem 0 0.2rem;
  }
  .index_footer .box .boxCen {
    margin-top: 0.4rem;
  }
  .index_footer .box .boxBot {
    padding: 0.36rem 0;
  }
}

@media screen and(max-width:768px) {
  .index_footer {
    height: auto !important;
  }
  .index_footer .box .boxCen .boxLeft {
    display: none;
  }
  .index_footer .box .boxTop {
    display: none;
  }
  .index_footer .box .boxCen {
    margin-top: 0;
    padding-top: 0.6rem;
  }
  .index_footer .box .boxBot .left {
    text-align: center;
    width: 100%;
  }
  .index_footer .box .boxBot .right {
    width: 100%;
    text-align: center;
    margin: 0.2rem 0 0;
  }
  .index_footer .box .boxBot {
    margin-top: 0.5rem;
    padding: 0.3rem 0 0.6rem;
  }
  .index_footer .box .boxCen .boxCenter .content3 p img {
    width: 0.48rem;
  }
}
@media screen and(max-width:669px) {
  .home .index_footer .box .boxCen {
    padding: 0;
  }
  .home .index_footer {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }
  .home .index_footer .box .boxBot {
    padding: 0.3rem 0 0rem;
  }
}
</style>
